import { AddToCalModal as AddLiveToCalModal } from 'src/components/Event/AddToCalModal'
import { AddToCalModal as AddVODToCalModal } from './AddToCalModal'
import { AddToCalButton } from 'src/components/Display/AddToCalButton'
import { Calendar as CalendarIcon } from 'src/components/Display/Calendar'
import { checkIsLiveClass } from 'src/utils/obeClassUtils'
import { formatInstructorNames } from 'src/utils/instructorUtils'
import { useLiveClassStatus } from 'src/hooks/useLiveClassStatus'
import { UserAction } from 'src/components/UserAction'
import { useRouter } from 'src/hooks/useRouter'
import { useTrainingJourney } from 'src/hooks/useTrainingJourney'

export const Calendar = ({
  className,
  isNewButton = false,
  video = {},
  isDisabled = false,
  handleUserJoinedClass = Function.prototype,
  trackingContext,
  onCalendarChange,
  children,
}) => {
  const [showModal, setShowModal] = React.useState(false)
  const [isAdded, setAdded] = React.useState(
    video?.reservation?.addedToCalendar || video?.addedToCalendar || false
  )
  const [reservationStartTime, setReservationStartTime] = React.useState(
    video?.reservation?.reservationStartTime
  )

  const { asPath } = useRouter()
  const { isEnded } = useLiveClassStatus(video)
  const { isReplay, title, instructors } = video
  const isLiveClass = checkIsLiveClass(video)

  const { id, duration, isGex = false } = video

  const trainingJourney = useTrainingJourney(isGex)

  let videoType = isLiveClass ? 'live' : isReplay ? 'replay' : 'vod'

  const onOpenCalendarModal = () => {
    if (!isDisabled) {
      setShowModal(true)
    }
  }

  const tracking = {
    duration: duration,
    instructors: formatInstructorNames(instructors),
    pageName: asPath,
    videoId: id,
    videoTitle: title,
    videoType: videoType,
    trainingJourney,
  }

  return (
    <React.Fragment>
      {!isNewButton ? (
        <UserAction
          className={className}
          onClick={onOpenCalendarModal}
          action={obe.events.opened_calendar_modal}
          isDisabled={isDisabled}
          tracking={tracking}
        >
          <React.Fragment>
            <Styles.Calendar isActive={isAdded} isDisabled={isDisabled} />
            {children && children}
          </React.Fragment>
        </UserAction>
      ) : (
        <AddToCalButton
          isActive={isAdded}
          onOpenCalendarModal={onOpenCalendarModal}
          handleUserJoinedClass={handleUserJoinedClass}
          className={className}
        />
      )}
      {isLiveClass ? (
        <AddLiveToCalModal
          event={video}
          isOpen={showModal}
          setIsOpen={setShowModal}
          isAdded={isAdded}
          setAdded={setAdded}
          eventEnded={isEnded}
          trackingContext={trackingContext ?? 'class page'}
          onCalendarChange={onCalendarChange}
        />
      ) : (
        <AddVODToCalModal
          video={video}
          isOpen={showModal}
          setIsOpen={setShowModal}
          isAdded={isAdded}
          setAdded={setAdded}
          reservationStartTime={reservationStartTime}
          setReservationStartTime={setReservationStartTime}
          onCalendarChange={onCalendarChange}
        />
      )}
    </React.Fragment>
  )
}

const Styles = {
  Calendar: styled(CalendarIcon)`
    width: 27px;
  `,
}
