import moment from 'moment'

export const useLiveClassStatus = ({ startTime, duration }, upcomingClass = {}) => {
  const endTime = moment(startTime).add(duration, 'seconds')
  const [elapsedTime, setElapsedTime] = React.useState(-1)
  const [isInProgress, setIsInProgress] = React.useState(moment().isBetween(startTime, endTime))

  const [isUpcoming, setIsUpcoming] = React.useState(moment().isBefore(startTime))
  const [isEnded, setIsEnded] = React.useState(moment().isAfter(endTime))

  const [secondsUntilLive, setSecondsUntilLive] = React.useState(-1)
  const intervalId = React.useRef(-1)

  React.useEffect(() => {
    clearInterval(intervalId.current)

    if (startTime) {
      intervalId.current = setInterval(() => {
        const newIsInProgress = moment().isBetween(startTime, endTime)
        const newIsUpcoming = moment().isBefore(startTime)
        const newIsEnded = moment().isAfter(endTime)

        setIsInProgress(newIsInProgress)
        setIsUpcoming(newIsUpcoming)
        setIsEnded(newIsEnded)

        if (newIsUpcoming) {
          setSecondsUntilLive(moment(startTime).diff(moment(), 'seconds'))
          setElapsedTime(0)
        } else if (newIsInProgress) {
          setSecondsUntilLive(0)
          setElapsedTime(moment().diff(moment(startTime), 'seconds'))
        } else {
          setSecondsUntilLive(-1)
          setElapsedTime(duration)
        }
      }, 1000)
    }

    return () => {
      clearInterval(intervalId.current)
    }
  }, [startTime, upcomingClass?.startTime])

  return {
    elapsedTime,
    isInProgress,
    isUpcoming,
    isEnded,
    secondsUntilLive,
  }
}
