import { button } from 'src/styles'
import { Calendar } from 'src/components/Display/Calendar'
import { UserAction } from 'src/components/UserAction'

export const AddToCalButton = ({
  className,
  handleUserJoinedClass,
  isActive,
  onOpenCalendarModal,
}) => {
  const handleAddToCal = () => {
    handleUserJoinedClass()
    onOpenCalendarModal()
  }
  return (
    <Styles.Button className={className} onClick={handleAddToCal}>
      <Styles.Calendar isActive={isActive} className={className} />
      <div>Add to calendar</div>
    </Styles.Button>
  )
}

const Styles = {
  Button: styled(UserAction)`
    ${button.newSecondary}
    width: 343px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: none;
    padding: 5px;

    :hover {
      path {
        fill: var(--persianBlue);
        stroke: var(--neutrals100);
      }
    }
  `,
  Calendar: styled(Calendar)`
    margin-right: 10px;

    svg {
      height: 18px;
      width: 18px;

      path {
        fill: var(--persianBlue);
        ${({ isActive }) =>
          isActive &&
          css`
            path {
              fill: var(--persianBlue);
            }
          `}
        path:nth-of-type(4) {
          stroke: var(--neutrals100) !important;
        }
      }
    }
  `,
}
