import jstz from 'jstz'
import { breakpoint } from 'src/styles'
import { addObeClassToCalendar, removeObeClassFromCalendar } from 'src/models/obeClass'
import { addToGoogleCalendar, buildiCalContent } from 'src/utils/calendarUtils'
import { useAsyncCallback } from 'react-async-hook'
import { button } from 'src/styles'
import { isMobile } from 'react-device-detect'
import { saveAs } from 'file-saver'
import { UserAction } from 'src/components/UserAction'
import { Dialog } from 'src/components/Dialog'
import { useTrainingJourney } from 'src/hooks/useTrainingJourney'
import moment from 'moment'

export const AddToCalModal = ({
  event,
  isOpen,
  setIsOpen,
  isAdded,
  setAdded,
  eventEnded,
  trackingContext,
  onCalendarChange,
}) => {
  const {
    id: eventId,
    startTime,
    duration,
    classType,
    category,
    instructors,
    title: eventTitle,
    reservation,
    isGex = false,
  } = event

  const trainingJourney = useTrainingJourney(isGex)

  const instructorNames =
    instructors?.map((instructor) =>
      instructor?.name
        ? instructor?.name?.trim()
        : `${instructor?.firstName || ''} ${instructor?.lastName || ''}`.trim()
    ) || []

  const title = `obé - ${eventTitle} with ${(
    instructorNames || instructors?.map(({ name }) => name)
  )?.join(' & ')}`
  const timezone = jstz.determine().name()
  const location = 'https://www.obefitness.com/'
  const timeFormat = 'YYYYMMDDTHHmmss'
  const startDateTime = moment(startTime).format(timeFormat)
  const endDateTime = moment(startTime).add(duration, 'seconds').format(timeFormat)

  const tracking = {
    eventId: eventId,
    classTitle: eventTitle || '',
    classCategory: classType?.name || category || '',
    classType: 'live',
    instructors: instructorNames?.join(', ') || '',
    startDate: moment(startDateTime).utc().toISOString(),
    endDate: moment(endDateTime).utc().toISOString(),
    rsvpFrom: trackingContext,
    friendsAtRsvp: reservation?.friendsAlsoAttendingCount || 0,
    trainingJourney,
  }

  const onCalendarClick = useAsyncCallback(async () => {
    if (isAdded) {
      setAdded(false)
      await removeObeClassFromCalendar(eventId)
      onCalendarChange && onCalendarChange(eventId, false)
    } else {
      setAdded(true)
      await addObeClassToCalendar(eventId, trackingContext)
      obe.analytics.track(obe.events.live.added_to_cal, tracking)
      onCalendarChange && onCalendarChange(eventId, true)
    }
  })

  const icsContent = buildiCalContent(
    document.URL,
    timezone,
    startDateTime,
    endDateTime,
    title,
    location
  )

  const addToiCal = () => {
    if (isMobile) {
      // Can't save a blob w/ calendar type with mobile Safari
      window.open('data:text/calendar;charset=utf8,' + escape(icsContent))
    } else {
      downloadIcs()
    }
  }

  const downloadIcs = () => {
    const blob = new Blob([icsContent], { type: 'text/calendar' })
    return saveAs(blob)
  }

  return (
    <Styles.Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <Styles.DialogHeader onClose={() => setIsOpen(false)} />
      <Styles.Body>
        <Styles.Header>Add to calendar</Styles.Header>
        <Styles.Subtitle>{eventTitle}</Styles.Subtitle>
        <Styles.Button
          isAdded={isAdded}
          isDisabled={onCalendarClick.loading || eventEnded}
          onClick={onCalendarClick.execute}
          disableTracking
        >
          {isAdded ? 'Remove from my obé Schedule' : 'Add to my obé Schedule'}
        </Styles.Button>
        <Styles.ButtonDivider />
        <Styles.SecondaryButton
          onClick={() => addToGoogleCalendar(startDateTime, endDateTime, timezone, location, title)}
          action={obe.events.live.added_to_cal}
          tracking={{ action: 'calendar_event_add_google', ...tracking }}
        >
          Google Calendar
        </Styles.SecondaryButton>
        <Styles.SecondaryButton
          onClick={addToiCal}
          action={obe.events.live.added_to_cal}
          tracking={{ action: 'calendar_event_add_ical', ...tracking }}
        >
          iCal
        </Styles.SecondaryButton>
        <Styles.SecondaryButton
          onClick={downloadIcs}
          action={obe.events.live.added_to_cal}
          tracking={{ action: 'calendar_event_add_outlook', ...tracking }}
        >
          Outlook
        </Styles.SecondaryButton>
      </Styles.Body>
    </Styles.Dialog>
  )
}

const Styles = {
  Dialog: styled(Dialog.Main)`
    max-width: 540px;
  `,
  DialogHeader: styled(Dialog.Header)`
    border-bottom: 0px;
  `,
  Header: styled.h1`
    font-size: 32px;
    font-weight: 300;

    ${breakpoint.mediumAndUp} {
      font-size: 46px;
    }
  `,
  Subtitle: styled.h2`
    font-weight: 300;
    font-size: 20px;
    margin: 7px 0 15px 0;

    ${breakpoint.mediumAndUp} {
      margin: 25px 0 35px 0;
    }
  `,
  Button: styled(UserAction)`
    ${button.newPrimary}

    ${({ isAdded }) =>
      isAdded &&
      css`
        :focus {
          box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
          outline: 0;
        }
        :hover {
          border-color: var(--steel);
        }
      `}
  `,
  SecondaryButton: styled(UserAction)`
    ${button.newSecondary}
    & + & {
      margin-top: 1rem;
    }
  `,
  Body: styled.div`
    padding: 0px 25px 35px 25px;
    text-align: center;

    ${breakpoint.mediumAndUp} {
      padding: 10px 83px 65px 83px;
    }
  `,
  ButtonDivider: styled.div`
    margin: 21px 0;
    width: 100%;
    border-bottom: 0.5px solid #d0d0d0;
  `,
}
